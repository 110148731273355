@media print {
    #Header,
    #PreprodWarning,
    .Footer {
        display: none
    }

    /* page système expert */
    body {
      print-color-adjust: exact;
    }

    .BestVarietiesForm {
      display: block !important;
    }

    .BestVarietiesForm-image,
    .BestVarietiesTable-buttons,
    button[type="submit"] {
        display: none !important
    }

    .BestVarietiesProducts,.BestVarietiesTable {
      background: white !important;
    }

    .BestVarietiesTable-tableContainer ,.BestVarietiesProducts {
      page-break-before: always;
    }

    .Select {
      background-image: none !important;
    }

    @page {size: landscape}
}
